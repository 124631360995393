import React, { useEffect } from "react";

const ICICC = () => {

  useEffect(() => {
    window.location.href = "http://icicc-conf.com";
  }, []);

  return (
    < >
    </>
  );
}

export default ICICC;
